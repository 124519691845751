@import 'colors.scss';
@import 'commons.scss';

html{
    min-height: 100%;
    margin: 0;
    padding: 0;
}

body{
    background-color: #e7ecee;
    min-height: 100%;
    margin: 0;
    padding: 0;
}

body.relatorio-wrapper{
    overflow: hidden;
}

*{
    // font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
}

.app {
    &.wrapper{
        margin-left: 230px;
        padding-right: 8px;
        padding-left: 8px;
        padding-top: 75px;
    }
}

.loading-wrapper {
    min-height: 90vh;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 768px) {

    .app.wrapper{
        margin-left: 0 !important;
    }

}