$primary: #10b1dc;
$secondary: #122f3b;
$gray: #72848c;
$red: #ff5555;
$green: #35C32A;
$yellow: #ffcb00;
$purple: #b620e0;

.purple-text{
    color: $purple;
}

.purple-bg{
    background-color: $purple;
}

.blue-text{
    color: $primary;
}
.blue-bg{
    background-color: $primary;
}

.gray-text{
    color: $gray;
}
.gray-bg{
    background-color: $gray !important;
    color: white !important;
}

.red-text{
    color: $red;
}
.red-bg{
    background-color: $red !important;
    color: white !important;
}

.MuiButton-contained{
    &.green-bg{
        background-color: $green !important;
        color: white !important;
    }
    &.gray-bg{
        background-color: $gray !important;
        color: white !important;
    }
    &.red-bg{
        background-color: $red !important;
        color: white !important;
    }
}

.green-text{
    color: $green;
}
.green-bg{
    background-color: $green;
}

.yellow-text{
    color: $yellow;
}
.yellow-bg{
    background-color: $yellow;
}