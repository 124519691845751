@import 'assets/scss/colors.scss';

.flash-wrapper{
    position: fixed;
    top: 20px;
    right: 0;
    z-index: 999;
    border-radius: 4px;

    &.error{
        .icon{
            background: linear-gradient(to right, #f6409f, $red);
            .wrapper{
                color: #ec1e5d;
            }
        }
    }

    .icon{
        position: relative;
        width: 60px;
        height: 60px;
        background: linear-gradient(to right, #0caedf, #2cd4c7);
        border-radius: 50%;
        color: white;
        padding: 5px;
        box-sizing: border-box;
        .wrapper{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: white;
            color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
    .close{
        position: absolute;
        right: 10px;
        top: 10px;
        color: $gray;
        cursor: pointer;
    }

    &.with-caption{

        .icon{
            width: 65px;
            height: 65px;
        }

        .content{
            height: 64px;
        }
    }

    .content{
        width: 400px;
        height: 48px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .title{
        text-transform: uppercase;
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: $gray;
        margin-top: 0;
        margin-bottom: 6px;
        margin-left: 20px;
    }
    .subtitle{
        font-size: 14px;
        font-family: Arial, Helvetica, sans-serif;
        color: $gray;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 4px;
        margin-left: 20px;
    }
   
}