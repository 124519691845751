@import 'colors.scss';

*:focus{
    outline: none !important;
}
.wrapper-full{
    min-height: 100vh;
}

.MuiButton-containedPrimary{
    color: white !important;
}

a{
    text-decoration: none;
    color: $primary;
    font-weight: normal;
}

.MuiPaper-elevation3 {
    box-shadow: 0px 1px 8px 0px rgba(180,180,180,0.2), 0px 3px 4px 0px rgba(180,180,180,0.14), 0px 3px 3px -2px rgba(180,180,180,0.12) !important;
}
.text-center{
    text-align: center;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23) !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: $primary !important;
}

.progress-yellow, .progress-red, .progress-green, .progress-blue{
    margin: 4px 0;
    background-color: #edf0f2 !important;
}
.progress-yellow div{
    background-color: #ffcb00 !important;
}

.progress-red div{
    background-color: #ff5555 !important;
}

.progress-green div{
    background-color: #35C32A !important;
}

.progress-blue div{
    background-color: #48BBFF !important;
}

.tag {
    line-height: normal;
    vertical-align: middle;
    font-size: 11px;
    color: white;
    display: inline-block;
    padding: 2px 5px;
}

.tag:nth-child(even) {
    margin-left: 5px;
}

.big-input{
    font-size: 38px;
    height: 60px;
    width: 80%;
    display: block;
    font-weight: bold;
    color: $gray;
    border: 0;
    margin-bottom: 40px;
    &::placeholder {
        color: $gray;
    }
}

table{
    width: 100%;
}
table tbody tr{
    line-height: 40px;
}
table thead tr th{
    &:first-child{
        padding-left: 0;
    }
    &:last-child{
        padding-right: 0;
    }
    font-weight: 500;
    padding: 0 10px;
    text-align: left;
    text-transform: none;
    font-size: 14px;
    color: #939EAB;
}

table tbody tr td{
    .MuiChip-labelSmall{
        color: white;
    }
    &:first-child{
        padding-left: 0;
    }
    &:last-child{
        padding-right: 0;
    }
    font-weight: 500;
    font-size: 14px;
    padding: 0 10px;
    color: #637280;
}

.box-header .single+.single{
    margin-left: 20px;
}

.box-header .single{
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    line-height: 40px;
}

.box-header .single.active{
    border-bottom: 3px solid #48BBFF;
}

.btn{
    &[disabled]{
        background-color: $gray;
        opacity: .6;
    }
    &+.btn{
        margin-left: 5px;
    }
    &.small{
        padding: 4px 6px;
        line-height: normal;
        display: inline-block;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    font-size: 13px;
    font-weight: 500;
    color: white;
    padding: 10px 15px;

    span{
        margin-left: 10px;
        font-weight: 500;
    }
}

.page-title{
    margin-bottom: 5px;
    width: 100%;

    a{
        display: flex;
        align-items: center;
        color: $gray;
    }

    svg{
        padding-right: 5px;
        cursor: pointer;
    }

    h3{
        display: inline-block;
        color: $gray;
        font-weight: normal;
        margin: 0;
        font-weight: 500;
    }
    small{
        color: $gray;
        margin-left: 8px;
        margin-bottom: 4px;
        display: inline-block;
        vertical-align: middle;
    }
}

.page-actions{
    &.borded{
        border-top: 1px solid #e0e0e0;
    }
    padding: 20px 0;

    button + button{
        margin-left: 10px;
    }
    a + button{
        margin-left: 10px;
    }
    a + a{
        margin-left: 10px
    }
    button + a{
        margin-left: 10px;
    }
}

.page-box{
    width: 100%;
    margin-top: 10px;
    .title{
        padding: 20px;
        color: white;
        font-weight: 500;
        background-color: #97a9b2;
        border-radius: 8px 8px 0 0;
    }
    .content{
        padding: 40px 20px;
    }

    &+.page-box{
        margin-top: 20px;
    }

}
.MuiSelect-select{
    span{
        font-weight: 500;
        text-decoration: none;
        font-style: normal;
        font-size: 15px;
        color: $secondary;
        line-height: 38px;
    }
}
.MuiMenu-list{
    li{
        span{
            font-weight: 500;
            text-decoration: none;
            font-style: normal;
            color: $secondary;
        }
    }
}
.select-wrapper{
    // height: 100%;
    .Mui-disabled{
        background-color: #ebebe4
    }

    label{
        display: block;
        font-weight: 500;
        color: $gray;
        margin-bottom: 5px;
    }

    .MuiSelect-select{
        height: 40px;
        font-size: 15px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.input-wrapper{
    &.autocomplete{
        .MuiAutocomplete-inputRoot{
            margin-top: 5px;
            padding-right: 30px !important;
            height: 40px;
            padding: 0;
            padding-left: 10px;
        }
        input{
            margin-top: 0;
            border: none;
            width: 100%;
        }
    }
    margin-bottom: 10px;
    label{
        display: block;
        font-weight: 500;
        color: $gray;
    }
    input, textarea{
        border: 1px solid #bfbfbf;
        padding: 0 10px;
        height: 40px;
        margin-top: 5px;
        color: $secondary;
        font-weight: 500;
        font-size: 15px;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        &:focus{
            outline: none
        }
    }
    textarea{
        padding: 10px;
        min-height: 120px;
    }
    .Mui-disabled {
        background: #ebebe4;
    }
    input:disabled {
        background: #ebebe4;
    }
}

.avatar-wrapper{
    position: relative;

    img{
        border-radius: 50%;
        border: 1px solid #ccc;
        height: 160px;
        width: 160px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    button{
        position: absolute;
        right: 15%;
        top: 60%;
    }
    input{
        display: none;
    }
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}

.primary-gradient{
    background: linear-gradient(to right, #07a7e3 0%,#32dac3 100%);
    color: white !important;
}
.show-mobile{
    display: none !important;
}

.hide-mobile{
    display: inherit !important;
}
.swal2-icon{
    width: 5rem;
    height: 5rem;
    border: .25rem solid transparent;
}
.swal2-icon .swal2-icon-content{
    font-size: 3.75rem;
}

@media only screen and (max-width: 768px) {

    .hide-mobile{
        display: none !important;
    }

    .show-mobile{
        display: inherit !important;
    }

}

